import React from "react";
import {Route, Switch, Redirect, withRouter, BrowserRouter} from 'react-router-dom';
import './App.css';
import Mapa from "./komponenti/mapa";
import Demo from "./komponenti/Demo";
// import Today from  "./komponenti/mobile/today";
import TodayNew from "./komponenti/mobile/todayNew"
import Hourly from "./komponenti/mobile/hourly";
// import Daily4days from "./komponenti/mobile/daily4days";
import Daily4daysNew from "./komponenti/mobile/daily4daysNew"



class App extends React.Component {

    render() {
        return (

            <div>
                <BrowserRouter>
                    <Switch>
                        <Route path="/" exact component={Mapa}></Route>
                        <Route path="/Demo" exact component={Demo}></Route>
                        <Route path="/Today/Municipality/:municipalityID" exact component={TodayNew}></Route>
                        <Route path="/Today/Municipality/:municipalityID/lat/:latitude/lng/:longitude/" exact component={TodayNew}></Route>
                        <Route path="/4days/Municipality/:municipalityID/Pollutant/:pollutantID" exact component={Daily4daysNew}></Route>
                        <Route path="/4days/Municipality/:municipalityID/Pollutant/:pollutantID/lat/:latitude/lng/:longitude/" exact component={Daily4daysNew}></Route>
                        <Route path="/Hourly/Municipality/:municipalityID" exact component={Hourly}></Route>
                        <Route path="/Hourly/Municipality/:municipalityID/lat/:latitude/lng/:longitude/" exact component={Hourly}></Route>
                        <Route path="/:lat/:lng" exact component={Mapa}></Route>
                        <Route path="/:lat/:lng/:details" exact component={Mapa}></Route>
                    </Switch>
                </BrowserRouter>
            </div>
        );
    }
}

export default withRouter(App);
